/* eslint-disable @typescript-eslint/no-explicit-any */
import QueryString from 'qs';

/**
 * Read token (if necessary) Only for serverside usage
 */
const token = process.env.NEXT_PUBLIC_STRAPI_READ_TOKEN;
const domain =
  process.env.NEXT_PUBLIC_STRAPI_ENDPOINT ||
  'https://staging-ocs.americastestkitchen.com';

export async function fetchStrapi<Data>(
  pathname: string,
  parameters: Partial<Record<'fields' | 'populate', any>>,
  options?: {
    noCache?: boolean;
    preview?: boolean;
  },
): Promise<{ data: Data | null; error: Record<string, unknown> | null }> {
  const queryString = QueryString.stringify({
    ...parameters,
    ...(options?.preview && { publicationState: 'preview' }),
  });
  const response = await fetch(`${domain}${pathname}?${queryString}`, {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...((options?.noCache || options?.preview) && {
        'Cache-Control': 'no-cache, no-store',
      }),
    },
  });

  // default to null so there's no serialization issues in serverside
  const {
    data = null,
    meta = null,
    error = null,
  } = (await response.json()) as any;

  if (error && process.env.NODE_ENV === 'development') {
    // local dev escaped
    // eslint-disable-next-line no-console
    console.log({ data, error, meta });
  }

  return { data, error };
}
