import styledNormalize from 'styled-normalize';
import { FF_MISE_AUTHORS } from 'server/lib/feature-flags';

const favoritesWidget = `
  ${!FF_MISE_AUTHORS ? styledNormalize : ''}
  /* some color names from color-blindness.com */
  /* stylelint-disable */
  /* stylelint-enable */
  /* h1 */
  /* h2 */
  /* h3 */
  /* SEARCH RESULTS */
  /* ARCHIE PROJECT VARIANTS */
  /* SEARCH SORT */
  /* breakpoints */
  /* custom breakpoints for archie work */
  /* content widths for archie work */
  /* grid units for archie work */
  /* stylelint-disable */
  /* stylelint-enable */
  .atkGlobalSiteHeader__search {
    position: relative; }
  
  .atkGlobalSearch__input::-ms-clear {
    display: none; }
  
  .document-autocomplete {
    background-color: #fff;
    border: 1px solid #000;
    border-top-width: 0;
    display: none;
    font: 1.4rem/1.64 "Merriweather";
    left: 0;
    margin-top: -1px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 0.9rem;
    position: absolute;
    top: calc(100% + 1px);
    z-index: 3000; }
    .cio-autocomplete-container .document-autocomplete {
      border-color: #d9cca7;
      color: #3f2b1e; }
      .cio-autocomplete-container .document-autocomplete__result {
        border-bottom: 1px dashed #e1d8c2; }
      .cio-autocomplete-container .document-autocomplete__result, .cio-autocomplete-container .document-autocomplete__category {
        color: #3f2b1e;
        font-family: "Merriweather"; }
        .cio-autocomplete-container .document-autocomplete__result:hover > span, .cio-autocomplete-container .document-autocomplete__result.focused > span, .cio-autocomplete-container .document-autocomplete__category:hover > span, .cio-autocomplete-container .document-autocomplete__category.focused > span {
          color: #94856b; }
    .cco-autocomplete-container .document-autocomplete {
      border-color: #000;
      color: #000; }
      .cco-autocomplete-container .document-autocomplete__result {
        border-bottom: 1px solid #d8d8d8; }
      .cco-autocomplete-container .document-autocomplete__result, .cco-autocomplete-container .document-autocomplete__category {
        color: #000; }
        .cco-autocomplete-container .document-autocomplete__result:hover > span, .cco-autocomplete-container .document-autocomplete__result.focused > span, .cco-autocomplete-container .document-autocomplete__category:hover > span, .cco-autocomplete-container .document-autocomplete__category.focused > span {
          color: #416490; }
    .atk-autocomplete-container .document-autocomplete {
      border-color: #c2c2c2;
      color: #3d3d3d; }
      .atk-autocomplete-container .document-autocomplete__result {
        border-bottom: 1px solid #d8d8d8; }
      .atk-autocomplete-container .document-autocomplete__result, .atk-autocomplete-container .document-autocomplete__category {
        color: #3d3d3d; }
        .atk-autocomplete-container .document-autocomplete__result:hover > span, .atk-autocomplete-container .document-autocomplete__result.focused > span, .atk-autocomplete-container .document-autocomplete__category:hover > span, .atk-autocomplete-container .document-autocomplete__category.focused > span {
          color: #d73a15; }
    .play-autocomplete-container .document-autocomplete {
      border-color: #262626;
      background-color: #262626;
      color: #fff; }
      .play-autocomplete-container .document-autocomplete__result {
        border-bottom: none; }
      .play-autocomplete-container .document-autocomplete__result, .play-autocomplete-container .document-autocomplete__category {
        color: #fff; }
        .play-autocomplete-container .document-autocomplete__result:hover > span, .play-autocomplete-container .document-autocomplete__result.focused > span, .play-autocomplete-container .document-autocomplete__category:hover > span, .play-autocomplete-container .document-autocomplete__category.focused > span {
          color: #6ba6aa; }
      .play-autocomplete-container .document-autocomplete__category.focused > span {
        outline: 2px dotted #fff;
        outline-offset: 3px; }
    .document-autocomplete.open {
      display: block !important; }
    .document-autocomplete__result {
      margin-top: 1px; }
    .document-autocomplete__category, .document-autocomplete__result {
      text-decoration: none;
      display: block; }
      .document-autocomplete__category:hover > span > span, .document-autocomplete__category.focused > span > span, .document-autocomplete__result:hover > span > span, .document-autocomplete__result.focused > span > span {
        text-decoration: underline; }
      .document-autocomplete__category.focused, .document-autocomplete__result.focused {
        outline: none; }
        .document-autocomplete__category.focused .document-autocomplete__category-content,
        .document-autocomplete__category.focused .document-autocomplete__result-content, .document-autocomplete__result.focused .document-autocomplete__category-content,
        .document-autocomplete__result.focused .document-autocomplete__result-content {
          outline: 2px dotted #3d3d3d;
          outline-offset: 3px; }
    .document-autocomplete__result-content, .document-autocomplete__category-content {
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-decoration: none; }
    .document-autocomplete__result-content {
      display: inline-flex;
      margin: 0.45rem 0; }
    .document-autocomplete__category-content {
      display: inline-block;
      line-height: 1.25;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      padding-bottom: 1px; }
      @media (min-width: 0) and (max-width: 667px) {
        .document-autocomplete__category-content {
          padding: 0.45rem 0; } }
    .document-autocomplete__category-label {
      display: inline-block;
      font-style: italic; }
    .document-autocomplete__category-list {
      padding-left: 0.8rem; }
      @media (min-width: 0) and (max-width: 667px) {
        .document-autocomplete__category-list {
          padding-left: 0; } }
    .document-autocomplete__categories {
      display: flex;
      align-items: baseline;
      padding-top: 0.45rem;
      padding-bottom: 1rem; }
      @media (min-width: 0) and (max-width: 667px) {
        .document-autocomplete__categories {
          display: block; } }
    .document-autocomplete__bolded {
      font-weight: bold; }
    .document-autocomplete__highlighted {
      font-weight: bold; }
      @media (min-width: 0) and (max-width: 667px) {
        .document-autocomplete__highlighted {
          display: inline-block;
          padding: 0.45rem 0; } }
  
  .favorites-popover {
    border: solid 1px #231f20;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: none;
    font: 14px/1.2 "proximaNovaRegular";
    left: 15px;
    max-height: calc(100% - 46px);
    max-width: calc(100% - 32px);
    padding: 12px;
    position: absolute;
    width: calc(100% - 32px);
    z-index: 300;
    position: absolute;
    background: #fff;
    border: 1px solid #231f20; }
    .favorites-popover::after, .favorites-popover::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .favorites-popover::after {
      border-color: rgba(35, 31, 32, 0);
      border-bottom-color: #fff;
      border-width: 13px;
      margin-left: -13px; }
    .favorites-popover::before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #231f20;
      border-width: 15px;
      margin-left: -15px; }
    .cio .favorites-popover {
      border-color: #d9cca7; }
      .cio .favorites-popover::before {
        border-bottom-color: #d9cca7; }
    .atk .favorites-popover__undo {
      color: #3d3d3d; }
    .cco .favorites-popover__undo {
      color: #000; }
    .cio .favorites-popover__undo {
      color: #3f2b1e; }
    .favorites-popover.open {
      display: block; }
    .favorites-popover-open {
      cursor: pointer;
      max-width: 100%;
      position: absolute; }
      @media (min-width: 1100px) {
        .favorites-popover-open {
          position: static; } }
    .favorites-popover__heading {
      display: flex;
      justify-content: space-between; }
      .favorites-popover__heading h3 {
        font: normal 16px/1.2 "proximaNovaBold", sans-serif;
        letter-spacing: 1px;
        margin: 0 0 10px;
        padding: 0;
        text-transform: uppercase; }
        .atk .favorites-popover__heading h3 {
          color: #3d3d3d; }
        .cco .favorites-popover__heading h3 {
          color: #000; }
        .cio .favorites-popover__heading h3 {
          color: #3f2b1e; }
        @media (min-width: 668px) {
          .favorites-popover__heading h3 {
            letter-spacing: 1.8px; } }
      .favorites-popover__heading svg {
        height: 16px;
        width: 16px; }
    .favorites-popover__error, .favorites-popover__success {
      color: #d73a15;
      font: normal 12px/1.2 "proximaNovaBold", sans-serif;
      margin-left: 0;
      margin-right: 0; }
    .favorites-popover__success {
      color: #3d3d3d; }
    .favorites-popover a {
      color: #000;
      font-family: "proximaNovaBold";
      text-decoration: underline; }
      .favorites-popover a:hover {
        color: #2e4a69; }
      .favorites-popover a:link, .favorites-popover a:visited {
        text-decoration: underline; }
      .atk .favorites-popover a {
        color: #3d3d3d; }
        .atk .favorites-popover a:hover {
          color: #767f81; }
      .cio .favorites-popover a {
        color: #3f2b1e; }
        .cio .favorites-popover a:hover {
          color: #94856b; }
    .favorites-popover__footer {
      justify-content: space-between;
      display: flex; }
      .favorites-popover__footer .dashboard-link {
        color: #416490;
        font: normal 12px/1.2 "proximaNovaBold", sans-serif;
        text-decoration: none; }
        .favorites-popover__footer .dashboard-link:hover {
          color: #2e4a69; }
        .atk .favorites-popover__footer .dashboard-link {
          color: #6ba6aa; }
          .atk .favorites-popover__footer .dashboard-link:hover {
            color: #5A8E91; }
        .cio .favorites-popover__footer .dashboard-link {
          color: #3f2b1e; }
          .cio .favorites-popover__footer .dashboard-link:hover {
            color: #94856b; }
          .cio .favorites-popover__footer .dashboard-link:link, .cio .favorites-popover__footer .dashboard-link:visited {
            text-decoration: none; }
      .jarvis .favorites-popover__footer {
        display: none; }
    .favorites-popover input[type=checkbox] {
      font: 14px/1.2 sans-serif;
      left: 0;
      position: absolute; }
    .favorites-popover label {
      cursor: pointer;
      font: 14px/1.2 "proximaNovaBold";
      padding-left: 20px; }
      .atk .favorites-popover label {
        color: #3d3d3d;
        font-family: "Montserrat", sans-serif; }
      .cco .favorites-popover label {
        color: #000;
        font-family: "proximaNovaRegular"; }
      .cio .favorites-popover label {
        color: #3f2b1e;
        font-family: "proximaNovaRegular"; }
    .favorites-popover strong {
      font-family: "proximaNovaBold";
      font-weight: normal; }
    .favorites-popover.centered {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
    @media (min-width: 668px) {
      .favorites-popover {
        width: 345px; }
        .favorites-popover .mobile-close {
          display: none; } }
  
  .collections-list {
    margin-bottom: 10px;
    max-height: 190px;
    overflow: auto;
    position: relative; }
    .collections-list__input {
      position: relative; }
      .cco .collections-list__input {
        /* Hover and focus states */
        /* Checked state */
        /* Hover state whilst checked */
        /* Disabled state */
        /* Check mark */
        /* Show check mark */
        /* Checkbox tick */
        /* Disabled tick colour */
        /* Disabled tick colour */ }
        .cco .collections-list__input .control {
          cursor: pointer;
          display: block;
          margin-bottom: 10px;
          min-height: 20px;
          padding-right: 3px;
          position: relative;
          padding-left: 30px; }
          .cco .collections-list__input .control input {
            opacity: 0;
            position: absolute;
            z-index: -1; }
        .cco .collections-list__input .control__indicator {
          background: #fff;
          border: 1px solid #c2c2c2;
          height: 20px;
          left: 0;
          position: absolute;
          top: 0;
          width: 20px; }
        .cco .collections-list__input .control:hover input ~ .control__indicator,
        .cco .collections-list__input .control input:focus ~ .control__indicator {
          background: #c2c2c2;
          border: 1px solid #c2c2c2; }
        .cco .collections-list__input .control input:checked ~ .control__indicator {
          background: #416490;
          border-color: #416490; }
        .cco .collections-list__input .control:hover input:not([disabled]):checked ~ .control__indicator,
        .cco .collections-list__input .control input:checked:focus ~ .control__indicator {
          background: #416490; }
        .cco .collections-list__input .control input:disabled ~ .control__indicator {
          pointer-events: none;
          opacity: 0.6;
          background: #e5e5e5; }
        .cco .collections-list__input .control__indicator::after {
          position: absolute;
          display: none;
          content: ''; }
        .cco .collections-list__input .control input:checked ~ .control__indicator::after {
          display: block; }
        .cco .collections-list__input .control--checkbox .control__indicator::after {
          top: 4px;
          left: 7px;
          width: 4px;
          height: 8px;
          transform: rotate(45deg);
          border: solid #fff;
          border-width: 0 2px 2px 0; }
        .cco .collections-list__input .control--checkbox.large {
          min-height: 3rem;
          padding-left: 4.25rem; }
          .cco .collections-list__input .control--checkbox.large .control__indicator {
            height: 3rem;
            width: 3rem; }
            .cco .collections-list__input .control--checkbox.large .control__indicator::after {
              height: 12px;
              left: 13px;
              top: 7px;
              width: 6px; }
        .cco .collections-list__input .control--checkbox input:disabled ~ .control__indicator::after {
          border-color: #3d3d3d; }
        .cco .collections-list__input .control--checkbox input:disabled ~ span {
          color: #989898; }
        .cco .collections-list__input .control-label {
          color: #3e2a1d;
          display: inline-block;
          vertical-align: middle; }
      .cio .collections-list__input {
        /* Hover and focus states */
        /* Checked state */
        /* Hover state whilst checked */
        /* Disabled state */
        /* Check mark */
        /* Show check mark */
        /* Checkbox tick */
        /* Disabled tick colour */
        /* Disabled tick colour */ }
        .cio .collections-list__input .control {
          cursor: pointer;
          display: block;
          margin-bottom: 10px;
          min-height: 20px;
          padding-right: 3px;
          position: relative;
          padding-left: 30px; }
          .cio .collections-list__input .control input {
            opacity: 0;
            position: absolute;
            z-index: -1; }
        .cio .collections-list__input .control__indicator {
          background: #fff;
          border: 1px solid #d9cca7;
          height: 20px;
          left: 0;
          position: absolute;
          top: 0;
          width: 20px; }
        .cio .collections-list__input .control:hover input ~ .control__indicator,
        .cio .collections-list__input .control input:focus ~ .control__indicator {
          background: #d9cca7;
          border: 1px solid #d9cca7; }
        .cio .collections-list__input .control input:checked ~ .control__indicator {
          background: #94856b;
          border-color: #94856b; }
        .cio .collections-list__input .control:hover input:not([disabled]):checked ~ .control__indicator,
        .cio .collections-list__input .control input:checked:focus ~ .control__indicator {
          background: #94856b; }
        .cio .collections-list__input .control input:disabled ~ .control__indicator {
          pointer-events: none;
          opacity: 0.6;
          background: #e5e5e5; }
        .cio .collections-list__input .control__indicator::after {
          position: absolute;
          display: none;
          content: ''; }
        .cio .collections-list__input .control input:checked ~ .control__indicator::after {
          display: block; }
        .cio .collections-list__input .control--checkbox .control__indicator::after {
          top: 4px;
          left: 7px;
          width: 4px;
          height: 8px;
          transform: rotate(45deg);
          border: solid #fff;
          border-width: 0 2px 2px 0; }
        .cio .collections-list__input .control--checkbox.large {
          min-height: 3rem;
          padding-left: 4.25rem; }
          .cio .collections-list__input .control--checkbox.large .control__indicator {
            height: 3rem;
            width: 3rem; }
            .cio .collections-list__input .control--checkbox.large .control__indicator::after {
              height: 12px;
              left: 13px;
              top: 7px;
              width: 6px; }
        .cio .collections-list__input .control--checkbox input:disabled ~ .control__indicator::after {
          border-color: #3d3d3d; }
        .cio .collections-list__input .control--checkbox input:disabled ~ span {
          color: #e5e5e5; }
        .cio .collections-list__input .control-label {
          color: #3e2a1d;
          display: inline-block;
          vertical-align: middle; }
      .atk .collections-list__input {
        /* Hover and focus states */
        /* Checked state */
        /* Hover state whilst checked */
        /* Disabled state */
        /* Check mark */
        /* Show check mark */
        /* Checkbox tick */
        /* Disabled tick colour */
        /* Disabled tick colour */ }
        .atk .collections-list__input .control {
          cursor: pointer;
          display: block;
          margin-bottom: 10px;
          min-height: 20px;
          padding-right: 3px;
          position: relative;
          padding-left: 30px; }
          .atk .collections-list__input .control input {
            opacity: 0;
            position: absolute;
            z-index: -1; }
        .atk .collections-list__input .control__indicator {
          background: #fff;
          border: 1px solid #c2c2c2;
          height: 20px;
          left: 0;
          position: absolute;
          top: 0;
          width: 20px; }
        .atk .collections-list__input .control:hover input ~ .control__indicator,
        .atk .collections-list__input .control input:focus ~ .control__indicator {
          background: #c2c2c2;
          border: 1px solid #c2c2c2; }
        .atk .collections-list__input .control input:checked ~ .control__indicator {
          background: #6ba6aa;
          border-color: #6ba6aa; }
        .atk .collections-list__input .control:hover input:not([disabled]):checked ~ .control__indicator,
        .atk .collections-list__input .control input:checked:focus ~ .control__indicator {
          background: #6ba6aa; }
        .atk .collections-list__input .control input:disabled ~ .control__indicator {
          pointer-events: none;
          opacity: 0.6;
          background: #e5e5e5; }
        .atk .collections-list__input .control__indicator::after {
          position: absolute;
          display: none;
          content: ''; }
        .atk .collections-list__input .control input:checked ~ .control__indicator::after {
          display: block; }
        .atk .collections-list__input .control--checkbox .control__indicator::after {
          top: 4px;
          left: 7px;
          width: 4px;
          height: 8px;
          transform: rotate(45deg);
          border: solid #fff;
          border-width: 0 2px 2px 0; }
        .atk .collections-list__input .control--checkbox.large {
          min-height: 3rem;
          padding-left: 4.25rem; }
          .atk .collections-list__input .control--checkbox.large .control__indicator {
            height: 3rem;
            width: 3rem; }
            .atk .collections-list__input .control--checkbox.large .control__indicator::after {
              height: 12px;
              left: 13px;
              top: 7px;
              width: 6px; }
        .atk .collections-list__input .control--checkbox input:disabled ~ .control__indicator::after {
          border-color: #3d3d3d; }
        .atk .collections-list__input .control--checkbox input:disabled ~ span {
          color: #c2c2c2; }
        .atk .collections-list__input .control-label {
          color: #3e2a1d;
          display: inline-block;
          vertical-align: middle; }
      .collections-list__input .control__indicator {
        display: block !important; }
      .collections-list__input .control--checkbox .control__indicator::after {
        left: 8px;
        width: 5px;
        height: 10px; }
      .collections-list__input .control-label {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .atk .collections-list__input .control-label {
          color: #3d3d3d; }
        .cco .collections-list__input .control-label {
          color: #000; }
        .cio .collections-list__input .control-label {
          color: #3f2b1e; }
  
  .collection-form {
    display: block;
    margin: 0 0 10px;
    overflow: hidden;
    padding: 0;
    width: 100%; }
    .collection-form > div {
      display: flex;
      padding: 5px 0; }
    .collection-form__input {
      flex-grow: 1;
      max-width: calc(100% - 75px); }
    .collection-form__button {
      flex-basis: 75px;
      width: 75px;
      min-width: 75px; }
    .collection-form input[type=text] {
      border: 1px solid;
      border-radius: 0;
      font: 12px/1 "Merriweather", serif;
      height: 35px;
      margin: 0;
      padding: 5px 10px;
      width: 100%;
      -webkit-appearance: none; }
      .collection-form input[type=text]::-moz-placeholder {
        color: #999999;
        font-family: "Merriweather" !important;
        font-size: 12px !important;
        font-style: italic;
        line-height: 1.2;
        opacity: 1;
        text-indent: 1px; }
      .collection-form input[type=text]::-webkit-input-placeholder {
        color: #999999;
        font: 12px "Merriweather";
        font-style: italic;
        line-height: 1.2;
        opacity: 1;
        text-indent: 1px; }
      .atk .collection-form input[type=text] {
        border-color: #3d3d3d; }
      .cco .collection-form input[type=text] {
        border-color: #000; }
      .cio .collection-form input[type=text] {
        border-color: #3f2b1e; }
      .collection-form input[type=text]::-moz-placeholder {
        line-height: 2.5rem; }
      @media (min-width: 0) and (max-width: 667px) {
        .collection-form input[type=text] {
          font: 16px/1 "Merriweather", serif; }
          .collection-form input[type=text]::-webkit-input-placeholder {
            line-height: 2; } }
    .collection-form.error {
      margin-bottom: 0; }
      .collection-form.error input[type=text] {
        background-color: #f9ded6;
        border-color: #d73a15; }
    .jarvis .collection-form {
      margin: 0; }
  
  .favorites-app-button {
    border-radius: 0;
    border: none;
    color: #fff;
    display: inline-block;
    font: 12px/35px "proximaNovaBold", sans-serif;
    height: 35px;
    letter-spacing: 1.4px;
    margin: 0;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle; }
    .favorites-app-button:hover {
      cursor: pointer; }
    .atk .favorites-app-button {
      background-color: #3d3d3d; }
      .atk .favorites-app-button:hover {
        background-color: #767f81; }
    .cco .favorites-app-button {
      background-color: #416490; }
      .cco .favorites-app-button:hover {
        background-color: #2e4a69; }
    .cio .favorites-app-button {
      background-color: #3f2b1e; }
      .cio .favorites-app-button:hover {
        background-color: #94856b; }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .favorites-popover-open {
      overflow-x: hidden; } }
`;

export default favoritesWidget;
