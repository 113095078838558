import { useRouter } from 'next/router';
import { liteClient as algoliasearch } from 'algoliasearch-new/lite';
import Header from '@americastestkitchen/ui/header/index';
import trackUI from './trackUI';
import { useUserHeader } from './useUserHeader';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';
const client = algoliasearch(APP_ID, API_KEY, {});

function getUserObject(user: any) {
  if (!user || user.reducedSegment === 'anonymous') {
    return { authenticated: false, member: false };
  }

  return {
    firstName: user.first_name ?? '',
    lastName: user.last_name ?? '',
    authenticated: true,
    member: user && user.reducedSegment === 'multisite',
  };
}

export default function UIHeader({ strapiData }: { strapiData: any }) {
  const user = useUserHeader();
  const { asPath } = useRouter();

  return (
    <Header
      currentPath={asPath}
      algoliaClient={client}
      track={trackUI}
      user={getUserObject(user)}
      strapiData={strapiData}
    />
  );
}
